<template>

    <div id="gallery" class="dark-green-background">

        <h3 class="minor-section-header white-text">Gallery</h3>

        <div class="gallery-content">

            <div class="unfocused gallery-image-prev">

                <img :src="require(`@/assets/images/${images[prev_image_index].imgUrl}`)" />

            </div>

            <div class="gallery-image">

                <img :src="require(`@/assets/images/${images[counter].imgUrl}`)" v-if="images[counter].video === false" />
                
                <!-- <video width="360px" height="auto" controls v-if="images[counter].video === true">
                    <source :src="require(`@/assets/videos/${images[counter].videoUrl}`)" type="video/mp4">
                </video> -->

                <iframe v-if="images[counter].video === true" width="560" height="315" :src="images[counter].video_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            </div>

            <div class="unfocused gallery-image-next">

                <img :src="require(`@/assets/images/${images[next_image_index].imgUrl}`)" />

            </div>

        </div>

        <div class="arrows">

            <img src="@/assets/images/arrow_white.svg" @click="minusCounter()" />

            <img class="forward-arrow" src="@/assets/images/arrow_white.svg" @click="plusCounter()" />

        </div>

        <div class="dots">

            <div class="circle white-background" v-for="image in images" :key="image.imgUrl" :class="{ 'green-background' : image === images[counter] }"></div>

        </div>


        <div class="footnote gray-text">The brand PlastiCoin was initially used and has been updated to PlastiPoint</div>


    </div>





</template>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'


export default {
    name: 'Gallery',
    components: {
        videoPlayer
    },
    data () {
        return {
            counter: 0,
            prev_image_index: Number,
            next_image_index: Number,
            images: [
                { 
                    imgUrl: "gallery1.png",
                    video: false
                },
                { 
                    imgUrl: "gallery2.png",
                    video: false
                },
                { 
                    imgUrl: "video_thumbnail_3.png",
                    video: true,
                    video_link: "https://www.youtube.com/embed/tmwyGFCRe2s"
                    // videoUrl: "plasticoin.mp4"
                },
                { 
                    imgUrl: "video_thumbnail_2.jpg",
                    video: true,
                    video_link: "https://www.youtube.com/embed/YvZ042Rwzq8"
                    // videoUrl: "plasticoin.mp4"
                },
                {
                    imgUrl: "gallery4.png",
                    video: false
                },
                {
                    imgUrl: "gallery5.png",
                    video: false
                },
                {
                    imgUrl: "gallery6.png",
                    video: false
                }
            ]
        }
    },
    created() {
        this.getNextImage();
        this.getPrevImage();
    },
    methods: {
        plusCounter() {
            var max = this.images.length - 1

            if (this.counter < max) {
                this.counter++
            } else {
                this.counter = 0
            }
            this.getNextImage()
            this.getPrevImage()   
        },
        minusCounter() {
            var min = 0
            var max = this.images.length - 1

            if (this.counter > min) {
                this.counter--
            } else {
                this.counter = max
            }      
            this.getNextImage()
            this.getPrevImage()      
        },
        getNextImage() {
            var max = this.images.length - 1
            if (this.counter < max) {
                this.next_image_index = this.counter + 1
            } else {
                this.next_image_index = 0
            }
        },
        getPrevImage() {
            var min = 0
            if (this.counter > min) {
                this.prev_image_index = this.counter -1
            } else {
                this.prev_image_index = this.images.length - 1
            }
        }
    }
}



</script>


<style scoped>

#gallery {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 500px;
    box-sizing: border-box;
    padding-bottom: 50px;
    margin-bottom: 50px;
}

h3 {
    text-align: center;
}

.gallery-content {
    width: 90%;
    margin: auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.gallery-image-prev {
    display: flex;
    justify-content: flex-end;
    width: 20%;
}

.gallery-image-next {
    display: flex;
    justify-content: flex-start;
    width: 20%;
}

.gallery-image {
    display: flex;
    justify-content: center;
    width: 60%;
    flex-direction: column;
    align-items: center;
    z-index: 5;
}

.gallery-image img {
    max-width: 500px;
    max-height: 500px;
}

.arrows {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    max-width: 700px;
    margin: auto;
    transform: translate(-50%, -50%);
    left: 50%;
}

.arrows img {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.forward-arrow {
    transform: rotate(180deg);
}

.unfocused img {
    height: 200px;
    object-fit: cover;
    opacity: 0.5;
}

.dots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin: auto;
    max-width: 300px;
    position: absolute;
    bottom: 20px;
    transform: translate(-50%, -50%);
    left: 50%;
}

.video-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.footnote {
    font-size: 10px;
    position: absolute;
    bottom: -20px;
    right: 10px;
}

</style>