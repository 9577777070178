<template>

    <div id="contact">

        <div class="modal message-modal" v-if="sending === true">

            <p>{{ sending_message }}</p>

            <div class="acknowledge-button" v-if="sent === true" @click="closeModal()">

                OK

            </div>

        </div>

        <div v-else>

            <h2 class="section-header green-text">Contact</h2>

            <p class="subhead gray-text">hello@plastipoint.com</p>


            <CircleTransition />

            <p class="subhead bold green-text social-refer">Find us on your preferred social media platforms, and spread the awareness for the cause.</p>

            <div class="social-icons">

                <a href="https://www.facebook.com/PlastiPoint" target="_blank">

                    <img src="@/assets/images/facebook.svg" />

                </a>

                <a href="https://www.instagram.com/plastipoint" target="_blank">

                    <img src="@/assets/images/instagram.svg" />

                </a>

                <a href="https://twitter.com/PlastiPoint" target="_blank">

                    <img src="@/assets/images/twitter.svg" />

                </a>

                <a href="https://www.linkedin.com/company/plastipoint" target="_blank">

                    <img src="@/assets/images/linkedin.svg" />

                </a>


            </div>

            <div class="newsletter-form green-background">

                <div class="form-content">

                    <div class="subhead white-text bold form-header">Don’t miss out on news and updates on our progress.</div>

                    <div class="input-container">

                        <p class="error">{{ error }}</p>

                        <form ref="form">

                            <input class="name-input" placeholder="Name" name="name" v-model="name" required />

                            <input class="email-input" placeholder="Email address" name="email" v-model="email" required />

                            <div class="subscribe-button" @click="sendEmail()">
                                Subscribe
                            </div>

                        </form>


                    </div>


                </div>

            </div>

        </div>
 
        <div class="footerimg" :style="{  'background-image': 'url(' + require('@/assets/images/ocean2.jpg') + ')', 'background-repeat' : 'no-repeat' }"></div>

    </div>



</template>


<script>
import CircleTransition from '@/components/CircleTransition.vue'
import emailjs from '@emailjs/browser';

export default {
    name: "Contact",
    components: {
        CircleTransition
    },
    data () {
        return {
            name: '',
            email: '',
            sending_message: '',
            sent: false,
            sending: false,
            error: ''
        }
    },
    methods: {
        sendEmail() {
            if (this.validEmail() && this.name != '') {
                this.sending = true
                this.sending_message = "Subscribing..."
                emailjs.sendForm('plastipoint', 'subscriber_template', this.$refs.form, 'qXAYvAE0yK-t4VcQp')
                    .then((result) => {
                        this.sent = true
                        this.sending_message = "Subscribed successfully!"
                        this.email = ''
                        this.name = ''
                        console.log('SUCCESS!', result.text);
                    }, (error) => {                    
                        console.log('FAILED...', error.text);
                    });
            } else {
                this.error = 'Please enter your name and a valid email address'
            }
        },
        closeModal() {
            this.sending = false
            this.sent = false
            this.error = ''
        },
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        }
    }
}



</script>

<style scoped>

/* #contact {

} */

.social-refer {
    margin-top: 50px;
}

.social-icons {
    width: 100%;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 30px;
}

.social-icons a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons img {
    max-width: 40px;
    max-height: 40px;
}

.newsletter-form {
   width: 100%;
   display: flex;
   flex-direction: column; 
   margin-top: 50px;
   box-sizing: border-box;
   padding: 50px;
}

.form-content {
    width: 90%;
    margin: auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
}

.form-header {
    text-align: left;
}

.input-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    flex-wrap: wrap;
}

input {
    border: 0px;
    font-size: 15px;
    box-sizing: border-box;
    padding: 10px;
    margin-right: 20px;
    font-family: 'Raleway', sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
}

.name-input {
    width: 200px;
}

.email-input {
    width: 300px;
}

.subscribe-button {
    border: 1px solid white;
    width: max-content;
    color: white;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 10px 40px 10px 40px;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
}

.subscribe-button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
}

.modal {
    background-color: #F7F9F9;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.acknowledge-button {
    background-color: #8DBF57;
    width: max-content;
    font-size: 25px;
    margin-top: 50px;
    box-sizing: border-box;
    padding: 20px 40px 20px 40px;
    color: white;
    cursor: pointer;
}

.message-modal p {
    font-size: 25px;
}

.error {
    font-weight: 600;
    font-size: 12.5px;
}

.footerimg {
  background-size: cover;
  height: 500px;
}

</style>