<template>

    <div id="testimonials">

        <div class="testimonial" v-for="testimonial in testimonials" :key="testimonial.text">

            <div class="testimonial-content" v-if="testimonials.indexOf(testimonial) === counter">

                <div class="testimonial-text gray-text">

                    <img src="@/assets/images/quotes.svg" />

                    <p>{{ testimonial.text }}</p>

                </div>

                <div class="testimonial-attribution gray-text">

                    {{ testimonial.attribution }}

                </div>

            </div>

        </div>

    </div>


</template>



<script>

export default {
    name: "Testimonials",
    data () {
        return {
            counter: 0,
            testimonials: [
                { 
                    text: "PlastiPoint has given me a job opportunity and it will help my family.",
                    attribution: "Mr. Green Africa Waste Collector"
                },
                {
                    text: "It’s encouraging not just for ourselves but our staff, to actually think about what they are throwing away, so it really has encouraged us to concentrate on recycling.",
                    attribution: "Consumer / PlastiPoint User"
                }
            ]
        }
    },
    mounted: function () {
        window.setInterval(() => {
          this.showTestimonials()
      }, 7000)
    },
    methods: {
        showTestimonials() {
            var max = this.testimonials.length - 1
            if (this.counter < max) {
                this.counter++
            } else {
                this.counter = 0
            }
        }
    }
}




</script>


<style scoped>

#testimonials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 90%;
    margin: auto;
    /* border: 1px dotted lightgray; */
}

.testimonial {
    
}

.testimonial-content {
    display: flex;
    flex-direction: column;
    animation: fade 1s linear;
}

.testimonial-text {
    display: flex;
    flex-direction: row;
    /* border-bottom: 1px dotted lightgray; */
    margin-bottom: 30px;
    /* flex-wrap: wrap; */
}

.testimonial-text img {
    width: 50px;
    height: 50px;
    max-width: 50px;
    max-height: 50px;
    margin-right: 20px;
}

.testimonial-attribution {
    font-weight: 600;
    text-align: center;
}

p {
    font-weight: 600;
    font-size: 25px;
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}



</style>