<template>

    <div id="about">

        <h2 class="section-header green-text">About</h2>

        <h3 class="subhead gray-text">We provide a leading-edge digital solution for community-based <span class="bold">plastic waste collection and recycling.</span></h3>

        <p class="section-content gray-text">Our solution digitally connects consumers, collectors, aggregators and recyclers to maximize plastic waste collection and prevent plastic waste from entering the environment. It helps local recyclers to significantly improve collection volumes and more efficiently collect the plastic waste.</p>

        <img src="@/assets/images/platform_diagram.svg" />

    </div>

</template>


<script>

export default {
    name: 'About'
}


</script>

<style scoped>

#about {
    box-sizing: border-box;
    padding: 50px 0px 50px 0px;
    width: 90%;
    margin: auto;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

p, h3, h2 {
    width: 100%;
    margin: auto;
    max-width: 700px;
    margin-bottom: 50px;
}

img {
    max-height: 400px;
    max-width: 100%;
    width: 100%;
}

</style>