<template>

    <div id="challenge" :style="{  'background-image': 'url(' + require('@/assets/images/ocean.jpg') + ')', 'background-repeat' : 'no-repeat' }">

        <div class="challenge-content">

          <h2 class="section-header white-text">The challenge</h2>

          <h3 class="subhead white-text shadow">The lack of robust local waste collection systems in emerging geographies has resulted in significant plastic waste ending up in the environment and our waterways.</h3>

        </div>

        <QuickFacts />

        <div class="challenge-content shadow">

          <p class="subhead white-text bold">Why should we care?</p>

          <p class="subhead white-text">Plastic pollution threatens <span class="bold">food safety and quality, human health, coastal tourism, and contributes to climate change</span>.</p>

        </div>



    </div>





</template>

<script>
import QuickFacts from '@/components/QuickFacts.vue'

export default {
    name: 'Challenge',
    components: {
        QuickFacts
    }
}



</script>

<style scoped>

#challenge {
  box-sizing: border-box;
  padding: 50px;
  /* background-size: 100%; */
  background-size: cover;
}

.challenge-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 700px;
  margin: auto;
  font-weight: 600 !important;
}


.challenge-content p {
  font-weight: 800 !important;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  padding: 10px;
  border-radius: 2.5px;
  font-weight: 600;
}


</style>