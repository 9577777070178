<template>

    <div id="home">

        <Banner />

        <Testimonials />

        <About />

        <WhyRecycle />

        <Challenge />

        <Solution />

        <ValueProposition />

        <Progress />

        <Gallery />

        <Plan />

        <BeAPart />

        <Partners />

        <Team />

        <Contact />

    </div>

</template>

<script>
import Banner from '@/components/Banner.vue'
import About from '@/components/About.vue'
import Challenge from '@/components/Challenge.vue'
import Solution from '@/components/Solution.vue'
import Progress from '@/components/Progress.vue'
import Plan from '@/components/Plan.vue'
import BeAPart from '@/components/BeAPart.vue'
import Partners from '@/components/Partners.vue'
import Team from '@/components/Team.vue'
import Contact from '@/components/Contact.vue'
import Gallery from '@/components/Gallery.vue'
import Testimonials from '@/components/Testimonials.vue'
import ValueProposition from '@/components/ValueProposition.vue'
import WhyRecycle from '@/components/WhyRecycle.vue'

export default {
    name: 'Home',
    components: {
        Banner,
        About,
        Challenge,
        Solution,
        Progress,
        Plan,
        BeAPart,
        Partners,
        Team,
        Contact,
        Gallery,
        Testimonials,
        ValueProposition,
        WhyRecycle
    }
}



</script>

<style scoped>



</style>