<template>

    <div id="solution">

        <div class="solution-text-content">

            <h2 class="section-header green-text">The solution</h2>

            <p class="subhead gray-text">We can greatly increase plastic collection at a community level by leveraging leading-edge digital capabilities.</p>

            <p class="subhead gray-text"><span class="bold green-text">PlastiPoint</span> connects <span class="bold">consumers, collectors, aggregators and recyclers</span> to enable a community-based collection infrastructure for use by local collectors and recyclers.</p>

            <p class="subhead gray-text">It is designed to complement local collection operations and can be easily configured, integrated and implemented.</p>

            <p class="subhead gray-text">The solution will be made available to local partners at no cost. This solution also provides incentives to consumers, collectors, and aggregators to collect and recycle plastic waste.</p>

            <p class="subhead gray-text">Our target is to implement <span class="bold green-text">PlastiPoint</span> in <span class="bold">1000+ local communities in the next 3 years</span>.</p>

        </div>

        <MobileDemo />

        <Capabilities />

    </div>


</template>

<script>
import MobileDemo from '@/components/MobileDemo.vue'
import CircleTransition from '@/components/CircleTransition.vue'
import Capabilities from '@/components/Capabilities.vue'

export default {
    name: 'Solution',
    components: {
        MobileDemo,
        CircleTransition,
        Capabilities
    },
    data () {
        return {

        }
    }
}





</script>

<style scoped>

#solution {
    box-sizing: border-box;
    padding: 50px 0px 50px 0px;
    width: 90%;
    margin: auto;
    /* max-width: 700px; */
    display: flex;
    flex-direction: column;
}

.solution-text-content {
    width: 100%;
    margin: auto;
    max-width: 700px;
    margin-top: 70px;
}
.value-point {
    display: flex;
    align-items: center;
}

</style>