<template>

    <div id="progress">

        <h2 class="section-header green-text">Progress</h2>

        <h3 class="subhead">We have completed initial implementation with <span class="super-bold dark-green-text">Mr. Green Africa</span> in Nairobi, Kenya.</h3>

        <div class="progress-content">

            <p class="gray-text">Consumers, Local Collectors and the Operations team effectively utilized the app</p>

            <p class="gray-text">Positive uptake and feedback from Consumers, Collectors and Mr. Green Africa</p>

            <p class="gray-text">Achieved and surpassed the initial plastic collection targets</p>

            <p class="gray-text">Currently implementing improvements and enhancements and in Nairobi</p>

            <p class="gray-text">Currently working to identify next implementations in Asia, Latin America and Africa</p>

        </div>

    </div>


</template>

<script>

export default {
    name: "Progress"
}


</script>

<style scoped>

#progress {
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1500px;
    margin-bottom: 50px;
}

p {
    font-weight: 600;
    text-align: center;
}

.progress-content {
    background-color: #F8F9F9;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px;
    border-radius: 0px 0px 20px 0px;
    width: 100%;
    max-width: 700px;
    margin: auto;
}

</style>