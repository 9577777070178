<template>

    <div id="circle-transition">
        <div class="circle blue-background"></div>
        <div class="circle green-background"></div>
        <div class="circle red-background"></div>
        <div class="circle dark-green-background"></div>
        <div class="circle black-background"></div>
    </div>


</template>

<script>

export default {
    name: 'CircleTransition'
}


</script>

<style scoped>

#circle-transition {
    margin-top: 100px !important;
    margin: auto;
    width: max-content;
    /* max-width: 200px; */
    display: flex;
    flex-direction: row;
    align-items: center;

}



</style>