<template>

    <div id="capabilities">

        <div class="minor-section-header dark-green-text"><span class="super-bold">PlastiPoint</span> Architecture</div>

        <Architecture />

        <div class="onboarding-header dark-green-text">Local Recycling Partner Onboarding Process</div>

        <img class="graphic-small" src="@/assets/images/onboarding.png" />

        <HowTo />

    </div>


</template>

<script>
import CircleTransition from '@/components/CircleTransition.vue'
import Architecture from '@/components/Architecture.vue'
import HowTo from '@/components/HowTo.vue'

export default {
    name: 'Capabilities',
    components: {
        CircleTransition,
        Architecture,
        HowTo
    }
}


</script>

<style scoped>

#capabilities {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    width: 95%;
    margin: auto;
    align-items: center;
}

.graphic-large {
    width: 90%;
    max-width: 800px;
    margin-top: 70px;
}

.graphic {
    width: 90%;
    max-width: 500px;
    margin-top: 70px;
}

.onboarding-header {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    margin-top: 70px;
}

.graphic-small {
    width: 90%;
    max-width: 300px;
    margin-top: 20px;
}

h3 {
    margin-top: 75px;
    margin-bottom: 0px;
}

</style>