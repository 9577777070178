<template>

    <div id="value-prop">

      <img src="../assets/images/plastipoint_9_valueprop_FINAL.png" />

        <!-- <h3>Value proposition</h3>

        <div class="text-content gray-text">

            <p v-for="value in value_points" :key="value">
                <span class="circle green-background"></span>
                {{ value }}
            </p>

        </div> -->

    </div>



</template>

<script>

export default {
    name: 'ValueProposition',
    data () {
        return {
            value_points: [
                "Significantly decrease plastic in the environment.",
                "Unique community-based recycling collection technology / model for use in emerging geographies to improve local collection rates.",
                "Generates attractive income for Local Recycling Partners and Collectors.",
                "A means and incentive for Consumers to collect recyclables.",
                "Provide a reliable supply of recycled plastics for downstream businesses."
            ]
        }
    }
}




</script>


<style scoped>

#value-prop {
  width: 100%;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  background-color: #F1FFE8;
  box-sizing: border-box;
  padding-bottom: 50px;
  padding-top: 50px;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 5% 50px 5%;
}

#value-prop img {
  max-width: 1000px;
  width: 90%;
}

h3 {
    color: white;
    background-color: #8CC63E;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    width: max-content;
    margin: auto;
    font-size: 30px;
    margin-bottom: -30px;
    z-index: 2;
    border-radius: 0px 0px 10px 0px;
}

.text-content {
    width: 90%;
    max-width: 700px;
    margin: auto;
    background-color: white;
    box-sizing: border-box;
    padding: 20px;
    border: 2px solid #8BC050;
    border-radius: 0px 0px 20px 0px;
    z-index: 1;
    position: relative;
}

.plus-icon {
    position: absolute;
    right: -50px;
    top: -50px;
    width: 100px;
    height: 100px;
}

p {
    font-size: 17.5px;
    font-weight: 600;
    margin: 25px 0px 25px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

p:last-child {
    margin: 25px 0px 12.5px 0px;
}


</style>