<template>

    <div class="arrow-container" :style="{ 'width' : width, 'height' : height }" :class="{ 'flex-column' : up === true || down === true }">

            <div class="arrow" :class="{ 'up' : up === true, 'left' : left === true }"></div>

            <div class="line" :class="{ 'line-up-down' : up === true || down === true }"></div>

            <div class="arrow" :class="{ 'down' : down === true, 'right' : right === true }"></div>


    </div>




</template>


<script>

export default {
    name: "Arrow",
    props: ["width", "height", "up", "down", "left", "right"]
}



</script>

<style scoped>


.arrow-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.line {
    display: flex;
    flex-grow: 1;
    border-bottom: 2px solid lightgray;
}

.line-up-down {
    border-right: 2px solid lightgray;
}

.arrow {
  border: solid lightgray;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  margin-left: -4px;
  margin-right: -4px;
  margin-bottom: -4px;
  margin-top: -4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}


</style>