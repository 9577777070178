<template>

    <div id="partner-form">

        <transition name="fade">

            <div class="modal message-modal" v-if="sending === true">

                <p>{{ sending_message }}</p>

                <div class="acknowledge-button" v-if="sent === true" @click="goHome()">

                    OK

                </div>

            </div>

        </transition>

        
        <h1>Become a partner</h1>

        <form ref="form">

            <input class="full-width" v-model="name" name="name" :placeholder="'Name'" required maxlength="100"  />

            <input class="half-width" v-model="email" name="email" :placeholder="'Email'" required maxlength="100" />

            <input class="half-width" v-model="phone" name="phone" :placeholder="'Phone'" required maxlength="30"/>

            <input class="full-width" v-model="organization" name="organization" :placeholder="'Organization (optional)'" maxlength="100" />

            <textarea v-model="interest" name="interest" :placeholder="'Describe your interest (optional)'" maxlength="500" />

            <div class="button-container">

                <div class="button" :class="{ 'active ' : validationsPass() }" @click="sendEmail()">Submit</div>

            </div>

        </form>


    </div>



</template>

<script>
import emailjs from '@emailjs/browser';

export default {
    name: 'Partner',
    data () {
        return {
            name: '',
            email: '',
            phone: '',
            organization: '',
            interest: '',
            error: '',
            sending: false,
            sent: false,
            sending_message: ''
        }
    },    
    created() {
        this.scrollToTop();
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        validationsPass() {
            if ((this.name.length > 3) && (this.validEmail()) && (this.validPhone())) {
                return true
            }
        },
        validEmail() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        validPhone() {
            if ((this.phone.length > 8) && (this.phone.length < 30)) {
                return true
            }
        },
        goHome() {
            this.$router.push('/')
        },
        sendEmail() {
            this.sending = true
            if (this.organization === '') {
                this.organization = 'Not provided'
            }

            if (this.interest === '') {
                this.interest = 'Not provided'
            }

            if (this.name != '' && this.validEmail() && this.phone != '') {
                this.sending = true
                this.sending_message = 'Sending your inquiry...'
                emailjs.sendForm('plastipoint', 'partner_inquiry', this.$refs.form, 'qXAYvAE0yK-t4VcQp')
                .then((result) => {
                    this.sent = true
                    this.sending_message = "Inquiry sent successfully! We'll get back to you as soon as we can."
                    this.email = ''
                    this.message = ''
                    this.name = ''
                    this.organization = ''
                    this.interest = ''
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    
                    if (this.organization === 'Not provided') {
                        this.organization = ''
                    }

                    if (this.interest === 'Not provided') {
                        this.interest = ''
                    }

                    console.log('FAILED...', error.text);
                });
            }
        },
    }
}



</script>

<style scoped>

body {
    background-color: #F7F9F9;
}

.modal {
    background-color: #F7F9F9;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
}

#partner-form {
    margin: auto;
    width: 90%;
    max-width: 1200px;
    margin-top: 75px;
    box-sizing: border-box;
    padding-top: 50px;
    min-height: 100vh;
}

h1 {
    background-color: #3A8D42;
    color: white;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 0px 0px 10px 0px;
    width: max-content;
    margin: 0px 0px -10px 10px;
    z-index: 2;
    position: relative;
}

form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    z-index: 1;
    position: static;
}

input {
    box-sizing: border-box;
    font-size: 20px;
    font-family: 'Raleway', sans-serif;
    padding: 30px 30px 30px 30px;
    margin: 0px 0px 0px 0px;
    border: 0px;
}

.full-width {
    border: 0px;
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    width: 100%;
}

.half-width {
    border: 0px;
    border-top: 1px solid lightgray;
    /* border-right: 1px solid lightgray; */
    border-left: 1px solid lightgray;
    width: 50%;
}

.half-width:nth-child(odd) {
    border-left: 0px;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
}

textarea {
    border: 0px;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
    box-sizing: border-box;
    margin: 0px 0px 20px 0px;
    /* border-radius: 5px; */
    padding: 30px 30px 30px 30px;
    font-family: 'Raleway', sans-serif;
    resize: none;
    display: flex;
    font-size: 20px;
    height: 200px;
    width: 100%;
}

.button {
    /* background-color: #8DBF57; */
    background-color: lightgray;
    color: white;
    color: gray;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    font-size: 20px;
    border: 0px;
    margin-bottom: 100px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    cursor: pointer;
    /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
    border-radius: 5px;
    margin-top: 100px;
    transition: 0.5s;
    text-align: center;
}

.active {
    background-image: linear-gradient(to right, #8DBF57 , #3A8D42);
    color: white;
    font-weight: 800;
}

.button-container {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    margin-bottom: 100px;
}

.error-container {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.error {
    color: #863A2D;
    margin: 0px 0px 0px 0px;
}

.message-modal {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.acknowledge-button {
    background-color: #8DBF57;
    width: max-content;
    font-size: 25px;
    margin-top: 50px;
    box-sizing: border-box;
    padding: 20px 40px 20px 40px;
    color: white;
    cursor: pointer;
}

.message-modal p {
    font-size: 25px;
}


@media(max-width: 600px) {

    #partner-form {
        width: 100%;
    }

    input {
        font-size: 15px;
        padding: 20px 10px 20px 10px;
        border-right: 0px;
        border-left: 0px;
    }

    .full-width {
        border-top: 0px;
        border-bottom: 1px solid lightgray;
    }

    .half-width {
        border-top: 0px;
        border-bottom: 1px solid lightgray;
    }

    .button {
        width: 90%;
        margin: auto;
    }


    h1 {
        margin-left: 0px;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .half-width {
        width: 100%;
        border-right: 1px solid lightgray;
    }

    textarea {
        font-size: 15px;
        padding: 20px 10px 20px 10px;
        border-top: 0px;
    }

    
}

</style>