<template>

    <div id="mobile-demo">

        <div class="mobile-demo-item">

            <img src="@/assets/images/mobile_view_1.jpg" />
            
            <h3 class="green-text">Consumer</h3>

            <p class="gray-text">Pick-up request</p>

            <p class="gray-text">Pick-up confirmation</p>

            <p class="gray-text">Drop-off locations</p>

            <p class="gray-text">Payment</p>

            <p class="gray-text">Incentives</p>

        </div>

        <div class="arrow-container">

            <img class="arrow" src="@/assets/images/arrow_lightgreen.svg" />

        </div>

        <div class="mobile-demo-item">

            <img src="@/assets/images/mobile_view_2.jpg" />

            <h3 class="green-text">Collector</h3>

            <p class="gray-text">Collector assignment</p>

            <p class="gray-text">Pick-up confirmation</p>

            <p class="gray-text">Routing</p>

            <p class="gray-text">Drop-off confirmation</p>

            <p class="gray-text">Payment</p>

            <p class="gray-text">Incentives</p>

            
        </div>

        <div class="arrow-container">

            <img class="arrow" src="@/assets/images/arrow_lightgreen.svg" />

        </div>

        <div class="mobile-demo-item">

            <img src="@/assets/images/mobile_view_3.jpg" />

            <h3 class="green-text">Aggregator</h3>

            <p class="gray-text">Process Drop-off</p>

            <p class="gray-text">Redeem Customer Rewards</p>

            <p class="gray-text">Aggregator Incentives & Payment</p>

        </div>

        <div class="arrow-container">

            <img class="arrow" src="@/assets/images/arrow_lightgreen.svg" />

        </div>

        <div class="mobile-demo-item">

            <img src="@/assets/images/mobile_view_4.jpg" />

            <h3 class="green-text">Recyclers</h3>

            <p class="gray-text">Drop-off notification</p>

            <p class="gray-text">Drop-off confirmation</p>

            <p class="gray-text">Payment</p>
            
        </div>
        
    </div>

</template>

<script>

export default {
    name: 'MobileDemo'
}


</script>

<style scoped>

#mobile-demo {
    width: 90%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 50px;
}

h3 {
    text-align: center;
}

.mobile-demo-item {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mobile-demo-item img {
    width: 100px;
    height: auto;
}

.mobile-demo-item p {
    margin: 5px 0px 5px 0px;
    text-align: center;
    font-weight: 800;
}

.arrow-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 6.66%;
    /* border: 1px solid black; */
}

.arrow {
    width: 30px;
    margin-top: -200px;
    margin-right: 10px;
    margin-left: 10px;
}

@media(max-width: 800px) {
    
    #mobile-demo {
        flex-direction: column;
        align-items: center;
    }
    
    .mobile-demo-item {
        width: 100%;
    }

    .arrow-container {
        width: 100%;
    }

    .arrow {
        transform: rotate(90deg);
        margin-top: 20px;
        margin-bottom: 20px;
    }
}


</style>