<template>

    <div id="partners">

        <h4 class="minor-section-header dark-green-text">Current Partners</h4>

        <div class="partner-icons">

            <a href="https://www.dow.com/en-us" target="_blank">

                <img src="@/assets/images/dow.png" />

            </a>

            <a href="https://www.microsoft.com/en-us/" target="_blank">
            
                <img src="@/assets/images/microsoft.png" />

            </a>

            <a href="https://www.tcs.com/" target="_blank">

                <img src="@/assets/images/tata.png" />

            </a>

            <a href="https://www.mrgreenafrica.com/" target="_blank">

                <img src="@/assets/images/mrgreenafrica.png" />

            </a>

        </div>


    </div>



</template>

<script>

export default {
    name: "Partners"
}



</script>

<style scoped>

#partners {
    width: 90%;
    margin: auto;
}

h4 {
    text-transform: uppercase;
    font-size: 30px;
}

.partner-icons {
    width: 100%;
    max-width: 700px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.partner-icons img {
    max-width: 100px;
    max-height: 100px;
}


</style>