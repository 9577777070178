<template>

  <div id="how-to">

    <h3 class="dark-green-text">How to recycle efficiently</h3>

    <h4 class="green-text">Follow the steps below for a proper disposal of usable waste.</h4>

    <div class="entries">

      <div class="entry">

        <img src="../assets/images/23b_1.png" />

        <p class="subhead gray-text">Separate and rinse your containers</p>


      </div>

      <div class="entry">

        <img src="../assets/images/23b_2.png" />

        <p class="subhead gray-text">Gather your containers until you are ready to recycle</p>

      </div>

      <div class="entry">

        <img src="../assets/images/23b_3_new.png" />

        <p class="subhead gray-text">Drop off or request pick up via PlastiPoint app</p>

      </div>

      <div class="entry">

        <img src="../assets/images/23b_4.png" />

        <p class="subhead gray-text">Help us achieve a circular economy</p>

      </div>

    </div>



  </div>




</template>


<script>

export default {
  name: "HowTo"
}





</script>

<style scoped>

#how-to {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
  margin-top: 75px;
}

h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
  margin: 0px 0px 20px 0px;
  font-size: 30px;
}

h4 {
  font-weight: 600;
  margin: 0px 0px 10px 0px;
  font-size: 25px;
  text-align: center;
}

.entries {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 600px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  box-sizing: border-box;
  padding: 0px 20px 0px 20px;
}

.entry img {
  width: 150px;
  height: 150px;
}

.entry p {
  font-weight: 600;
  font-size: 20px;
}

@media(max-width: 500px) {
  .entry {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }

  .entries {
    box-sizing: border-box;
    padding: 0px 10% 0px 10%;
  }
}


</style>