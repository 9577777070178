<template>

  <div id="why-recycle">

    <h3 class="dark-green-text">Why recycle plastic packaging?</h3>

    <div class="entries">

      <div class="entry">

        <img src="../assets/images/23a_1.png" />

        <p class="subhead gray-text">We reduce the pollution of waste in the world.</p>


      </div>

      <div class="entry">

        <img src="../assets/images/23a_2.png" />

        <p class="subhead gray-text">We emit fewer greenhouse gases by of waste in the world reusing a product that has already been previously extracted and processed, reducing the environmental impact.</p>

      </div>

      <div class="entry">

        <img src="../assets/images/23a_3.png" />

        <p class="subhead gray-text">We help needy families and the country’s economy through the business that is recycling.</p>

      </div>



    </div>



  </div>




</template>


<script>

export default {
  name: "WhyRecycle"
}





</script>

<style scoped>

#why-recycle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 75px;
}

h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  margin-top: 70px;
  margin: 0px 0px 20px 0px;
}

.entries {
  display: flex;
  flex-direction: row;
  margin: auto;
  max-width: 1000px;
  margin-top: 50px;
  flex-wrap: wrap;
}

.entry {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  box-sizing: border-box;
  padding: 0px 20px 0px 20px;
}

.entry img {
  width: 100px;
  height: 100px;
}

.entry p {
  font-weight: 600;
}

@media(max-width: 500px) {
  .entry {
    width: 100%;
    margin: 10px 0px 10px 0px;
  }

  .entries {
    box-sizing: border-box;
    padding: 0px 10% 0px 10%;
  }
}


</style>