<template>
    
    <div id="team" class="dark-green-background">

        <div class="team-content">

            <h2 class="section-header white-text">Our team</h2>

            <h3 class="subhead white-text"><span class="bold">PlastiPoint</span> is led by a team of people who are passionate about eliminating plastic waste from the environment.</h3>

            <div v-for="member in team_members" :key="member.name" class="team-member">

                <p class="bold">{{ member.name }}</p>

                <p class="position">{{ member.position }}</p>

            </div>

        </div>

    </div>


</template>

<script>
export default {
    name: "Team",
    data () {
        return {
            team_members: [
                { 
                    name: "David McLean",
                    position: "CEO"
                },
                { 
                    name: "Steven Popovich",
                    position: "COO"
                },
                { 
                    name: "Jerry Burns",
                    position: "Technical Lead"
                },
                { 
                    name: "Marci Toporski",
                    position: "Program Lead"
                },
                { 
                    name: "Sydney Cox",
                    position: "Project Manager / Scrum Manager"
                },
                { 
                    name: "Jim Schroeder",
                    position: "Testing Leader"
                },
                {
                    name: "Irene Wambugu",
                    position: "Implementation Lead"                 
                }
            ]
        }
    }
}
</script>

<style scoped>

#team {
    box-sizing: border-box;
    padding: 50px;
    margin-top: 50px;
}

h3 {
    margin-bottom: 25px;
}

.team-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 700px;
    margin: auto;
    align-items: center;
}

.team-member {
    width: 100%;
    text-align: center;
    /* margin: 10px 0px 10px 0px; */
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    width: max-content;
    /* border-bottom: 1px solid lightgray; */
}

.team-member:last-child {
    border-bottom: 0px;
}

.team-member p {
    /* padding: 5px 0px 5px 0px; */
    box-sizing: border-box;
    margin: 2.5px 0px 2.5px 0px;
    color: white;
}

.position {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 10px;
    letter-spacing: 0.5px;
}



</style>