<template>


    <div id="be-a-part">

        <img class="banner-image" src="@/assets/images/banner_img.jpg" />

        <div class="box green-background">

            <h3 class="white-text">Be a part of our journey to reduce plastic waste in our environment.</h3>

            <div class="subhead white-text">
                This is a critical journey, and we are excited to be part of the solution. We are keenly interested in working with additional partners:
            </div>

            <div class="list-item">
                <span class="circle white-background"></span>
                <p class="white-text">Local Collection & Recycling Partners</p>
            </div>

            <div class="list-item">
                <span class="circle white-background"></span>
                <p class="white-text">Funders & Investors</p>
            </div>

            <div class="list-item">
                <span class="circle white-background"></span>
                <p class="white-text">Value Chain & Technical Partners</p>
            </div>

            <div class="list-item">
                <span class="circle white-background"></span>
                <p class="white-text">Solution Distribution Partners</p>
            </div>

            <div class="button green-text" @click="goToPartnerForm()">
                Become a Partner
            </div>


        </div>



    </div>


</template>

<script>

export default {
    name: "BeAPart",
    methods: {
        goToPartnerForm() {
            this.$router.push('/partner')
        }
    }
}


</script>

<style scoped>

#be-a-part {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    height: 600px;
    align-items: center;
    justify-content: flex-end;
    margin-top: 75px;
}

.banner-image {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    height: 600px;
    width: 100%;
}

.box {
    width: 90%;
    max-width: 800px;
    color: white;
    box-sizing: border-box;
    padding: 25px;
    z-index: 5;
    position: relative;
    height: max-content;
    margin: auto;
    border-radius: 0px 0px 20px 0px;
    align-self: center;
}

h3 {
    font-weight: 400;
    font-size: 40px;
    margin: 0px 0px 20px 0px;
}

.subhead {
    text-align: left;
}

.list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    background-color: white;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    font-weight: 800;
    border-radius: 20px;
    width: max-content;
    margin-top: 50px;
    margin-bottom: -45px;
    cursor: pointer;
    font-size: 20px;
}

.button:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

@media(max-width: 700px) {
    #be-a-part {
        height: 800px;
    }

    .banner-image {
        height: 800px;
    }  
}

</style>