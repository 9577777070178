<template>

    <div id="plan">

        <h2 class="minor-section-header gray-text"><span class="green-text">PlastiPoint</span> plan for the next 3 years:</h2>

        <div class="plan-box">

            <div class="roll-out dark-green-background">Roll-Out in Kenya</div>

                <h3 class="gray-text">Working to roll-out full <span class="bold green-text">PlastiPoint</span> application in <span class="bold">4 cities in Kenya</span>:</h3>

                <div class="cities">
                    <p class="gray-text">Nairobi</p>
                    <div class="circle dark-green-background"></div>
                    <p class="gray-text">Kisumu</p>
                    <div class="circle dark-green-background"></div>
                    <p class="gray-text">Mombasa</p>
                    <div class="circle dark-green-background"></div>
                    <p class="gray-text">Eldoret</p>
                </div>

                <img class="map" src="@/assets/images/map_kenya.png" />


        </div>

        <div class="small-header dark-green-text">Following Kenya Roll-Out</div>

        <div class="expansion gray-text">Our goal is to rapidly expand to <span class="green-text bold">1000+ communities</span> <span class="gray-text bold">across multiple regions in 3 years.</span></div>

    </div>



</template>

<script>

export default {
    name: "Plan"
}


</script>

<style scoped>

#plan {
    width: 90%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

h3 {
    font-weight: 400;

}

.plan-box {
    border: 3px solid #8BC050;
    box-sizing: border-box;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    max-width: 700px;
    border-radius: 0px 0px 20px 0px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.roll-out {
    width: max-content;
    margin: auto;
    font-size: 25px;
    color: white;
    font-weight: 800;
    box-sizing: border-box;
    padding: 10px;
    margin-top: -55px;
}

.cities {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 50px;
}

.cities p {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.25px;
}

.map {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
}

.small-header {
    font-weight: 400;
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 30px;
    text-align: center;
}

.expansion {
    font-size: 25px;
    text-align: center;

}

</style>