<template>
  <div id="app">
    <Navbar />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components:{
    Navbar,
    Footer
  }
}


</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;400;600;800&display=swap');


#app {
  font-family: 'Raleway', sans-serif;
}

html {
  min-height: 100%;
  background-color: white;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  height: 100%;
}

.filler {
  flex-grow: 1;
}

.green-text {
  color: #8BC050 !important;
}

.white-text {
  color: white;
}

.gray-text {
  color: #666767;
}

.green-background {
  background-color: #8BC050 !important;
}

.dark-green-background {
  background-color: #3A8D42;
}

.dark-green-text {
  color: #3A8D42;
}

.blue-background {
  background-color: #55B1E3;
}

.red-background {
  background-color: #D21F2D;
}

.black-background {
  background-color: black;
}

.white-background {
  background-color: white;
}

.section-header { 
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  font-size: 45px;
  letter-spacing: 0.5px;
}

.minor-section-header {
  font-weight: 600;
  text-align: center;
  font-size: 25px;
}

.subhead {
  font-weight: 400;
  text-align: center;
  line-height: 27.5px;
}

.section-content {
  font-weight: 400;
  text-align: center;
  line-height: 25px;
}

.bold {
  font-weight: 600;
}

.super-bold {
  font-weight: 800;
}

.circle {
  width: 10px !important;
  height: 10px !important;
  max-width: 10px;
  min-width: 10px;
  border-radius: 50%;
  margin: 0px 10px 0px 10px;
}

</style>
