<template>

    <div id="banner">

        <img class="banner-image" src="@/assets/images/bannerimagenew.jpg" />

        <div class="banner-content">

            <div v-for="text in banner_text" :key="text" class="banner-text green-background">
                <h2>{{ text }}</h2>
            </div>

        </div>

    </div>




</template>



<script>
import Banner from '@/components/Banner.vue'

export default {
    name: 'Banner',
    data () {
        return {
            banner_text: ["Join in our commitment to reduce plastic waste entering the environment using our unique, digital waste collection mobile app."]
        }
    }
}


</script>

<style scoped>

#banner {
    width: 100%;
    display: flex;
    position: relative;
    height: 500px;
    align-items: center;
    margin-top: 75px;
}

.banner-image {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    height: 500px;
    width: 100%;
}

.banner-content {
    width: 90%;
    margin: auto;
    max-width: 1000px;
}

.banner-text {
    max-width: 500px;
    width: 100%;
    color: white;
    box-sizing: border-box;
    padding: 25px;
    z-index: 5;
    position: relative;
    height: max-content;
    border-radius: 0px 0px 20px 0px;
}

.banner-text h2 {
    font-size: 30px;
    font-weight: 600;
}


</style>