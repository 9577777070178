<template>

    <div id="footer" class="dark-green-background">

        <div class="white-text footer-button">© 2022 PlastiPoint. All rights reserved.</div>

    </div>



</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>

#footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 50px;
    align-items: center;
}

.footer-button {
    font-size: 14px;
}


</style>