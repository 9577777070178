<template>

    <div id="navbar">
        
        <div class="navbar-content">
            
            <div class="navbar-icon" @click="goHome()">
                <img src="@/assets/images/plastipoint-wm-lg.svg" />
            </div>

            <div class="filler"></div>

            <div class="navbar-buttons" :class="{ 'navbar-buttons-expanded' : navbar_expand_toggled === true }">

                <p class="navbar-button" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goTo('about')">About</p>

                <p class="navbar-button" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goTo('challenge')">The challenge</p>

                <p class="navbar-button" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goTo('solution')">The solution</p>

                <p class="navbar-button" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goTo('progress')">Progress</p>

                <p class="navbar-button green-text" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goToPartnerForm()">Become a Partner</p>

                <p class="navbar-button" :class="{ 'navbar-button-expanded' : navbar_expand_toggled === true }" @click="goTo('contact')">Contact us</p>

            </div>
            
            <div class="filler mobile-filler"></div>

            <img class="navbar-expand-button" src="@/assets/images/expandbutton.svg" v-if="navbar_expand_toggled === false" @click="navbar_expand_toggled = !navbar_expand_toggled" />

            <img class="navbar-expand-button close-navbar-button" src="@/assets/images/expandbuttonclose.svg" v-if="navbar_expand_toggled === true" @click="navbar_expand_toggled = !navbar_expand_toggled" />

        </div>



    </div>




</template>

<script>
export default {
    name: 'Navbar',
    data () {
        return {
            navbar_expand_toggled: false
        }
    },
    methods: {
        goTo(section) {
            this.$router.replace('/')
            this.$nextTick(() => {
                this.navbar_expand_toggled = false;
                const id = section;
                const yOffset = -150; 
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            });
        },
        goToPartnerForm() {
            this.navbar_expand_toggled = false;
            this.$router.push('/partner')
        },
        goHome() {
            this.$router.replace('/');
        }
    }
}



</script>

<style scoped>

#navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 0px 20px 0px;
    position: fixed;
    top: 0px;
    z-index: 10;
    height: 75px;
}

.navbar-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    max-width: 1500px;
    margin: auto;
}

.navbar-icon {
    margin-right: 50px;
    cursor: pointer;
}

.navbar-icon img {
    width: 150px;
}

.navbar-expand-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.navbar-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.navbar-buttons-expanded {
    position: absolute;
    top: 70px;
    background-color: white;
    left: 0px;
    height: 100vh;
    box-sizing: border-box;
    padding-left: 2.5%;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.2s;
}

.navbar-stroke {
    width: 100%;
    position: relative;
    top: 50px;
    border-bottom: 1px solid lightgray;
    margin: 50px 0px 10px 0px;
}

.navbar-button {
    width: max-content;
    margin: 10px 30px 10px 0px;
    color: #666767;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
}

.navbar-expand-button {
    display: none;
}

.close-navbar-button {
    display: flex;
}

.navbar-button:hover {
    color: #8BC050;
    transition: 0.2s;
}

.mobile-filler {
    display: none;
}

@media(max-width: 1150px) {
    .navbar-button {
        display: none;
    }

    .navbar-button-expanded {
        display: flex;
    }

    .navbar-button-expanded:first-child {
        box-sizing: border-box;
        padding-top: 20px;
        border-top: 1px solid lightgray;
    }

    .navbar-expand-button {
        display: flex;
    }

    .mobile-filler {
        display: flex;
    }
}


</style>