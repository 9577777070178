<template>

    <div id="quick-facts">

        <div class="quick-fact" v-for="fact in quick_facts" :key="fact.statistic">
            <p class="stat dark-green-text">{{ fact.statistic }}</p>
            <p class="text green-text">{{ fact.text }}</p>
        </div>

    </div>



</template>

<script>

export default {
    name: 'QuickFacts',
    data () {
        return {
            quick_facts: [
                { 
                    statistic: '80%',
                    text: 'of ocean waste comes from 10 river systems in emerging geographies'
                },
                { 
                    statistic: '30%',
                    text: 'of plastic waste in emerging geographies is leaked into the environment'
                },    
                { 
                    statistic: '14',
                    text: 'million tons of plastic end up in the ocean every year'
                }             
            ]
        }
    }
}





</script>

<style scoped>

#quick-facts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1500px;
    margin: auto;
}

.quick-fact {
    margin: 40px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    /* height: max-content; */
    padding: 20px;
    width: 30%;
}

.stat {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
}

.text {
    font-weight: 600;
    text-align: center;
}

@media(max-width: 900px) {

    #quick-facts {
        flex-direction: column;
    }

    .quick-fact {
        width: 100%;
    }
}

</style>