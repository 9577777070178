<template>

    <div id="architecture">

        <div class="screenshot">

            <img src="../assets/images/mobile_view_1.jpg" />

            <p class="available">Available on</p>

            <p class="available-large">Android & iOS</p>

        </div>

        <div class="arrow-container">

          <Arrow :left="true" :right="true" :width="'60%'" />

        </div>

        <div class="big-box green-background flex-column">

            <h3>PlastiPoint</h3>

            <div class="points">
              
              <p>• Identity / Security</p>

              <p>• GIS / Location</p>

              <p>• Routing</p>

              <p>• Wallet / Payment</p>

              <p>• Database</p>

            </div>

        </div>

        <div class="small-boxes-container">

            <div class="middle-arrows">

                <div class="slot">

                    <Arrow :left="true" :right="true" :width="'60%'" />

                </div>

                <div class="slot middle-slot">

                    <Arrow :left="true" :right="true" :width="'60%'" />

                </div>

                <div class="slot">

                    <Arrow :left="true" :right="true" :width="'60%'" />

                </div>


            </div>

            <div class="right-boxes">

                <div class="box-slot top-box">

                    <div class="box dark-green-background">
                        
                        <p>Local Recycling Partners Back Office</p>

                    </div>

                </div>

                <div class="box-slot middle-slot">

                    <div class="left-container">

                        <Arrow :up="true" :down="true" :height="'20px'" />

                        <div class="box middle-box dark-green-background">
                                
                            Digital Wallet

                        </div>

                        <Arrow :up="true" :down="true" :height="'20px'" />

                    </div>

                    <div class="right-arrow-slot">

                        <Arrow :up="true" :down="true" :height="'130px'" />

                    </div>
                    
                </div>

                <div class="box-slot bottom-box">
                   
                   <div class="box dark-green-background">
                        
                        Payment Processor

                    </div>

                    
                </div>

            </div>




        </div>


    </div>



</template>

<script>
import Arrow from '@/components/Arrow.vue'

export default {
    name: 'Architecture',
    components: {
        Arrow
    }
}

</script>


<style scoped>

#architecture {
    width: 100%;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    /* border: 1px dotted lightgray; */
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    background-color: #F7F9F9;
}

h3 {
  margin: 0px 0px 0px 0px;
}

.big-box {
    width: 60%;
    height: 300px;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: 0 0 16px rgba(140, 198, 62, 0.2);
}

.small-boxes-container {
    display: flex;
    flex-direction: row;
    width: 40%;
}

.middle-arrows {
    width: 20%;
    display: flex;
    flex-direction: column;
}

.top-slot {
    height: 75px;
}

.slot {
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.middle-slot {
    height: 150px;
}

.slot img {
    width: 90%;
    margin: auto;
}

.right-boxes {
    width: 90%;
    display: flex;
    flex-direction: column;
}

.top-box {
    height: 75px;
    width: 100%;
}

.bottom-box {
    height: 75px;
    width: 100%;
}

.box-slot {
    height: 75px;
}

.box {
    height: 75px;
    /* color: white; */
    color: #F7F9F9;
    width: 100%;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 20px 10px 20px;
    text-align: center;
    flex-direction: column;
    border-radius: 5px;
}

.box p {
    margin: 0px 0px 0px 0px;
}

.middle-box {
    width: 100%;
    margin: 10px 0px 10px 0px;
}

.middle-slot {
    height: 150px;
    display: flex;
    align-items: center;
}

.left-container {
    width: 70%;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.arrow-short {
    height: 30px;
}

.arrow-tall {
    height: 140px;
}

.right-arrow-slot {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.points {
  margin-top: 25px;
}

.points p {
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  font-weight: 500;
}

.screenshot {
  margin-right: 25px;
}

.screenshot img {
  max-height: 200px;
  border: 1px solid lightgray;
  border-radius: 2.5px;
}

.available {
  color: #3A8D42;
  margin: 10px 0px 0px 0px;
  text-align: center;
}

.available-large {
  color: #3A8D42;
  margin: 5px 0px 0px 0px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.arrow-container {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  height: 300px;
  /* border: 1px dotted lightgray; */
}

@media(max-width: 600px) {
    .big-box {
        width: 40%;
        box-sizing: border-box;
        padding: 0px 5px 0px 5px;
    }

    #architecture {
      background-color: rgba(0, 0, 0, 0);
    }

    .box {
      font-size: 12.5px;
    }

    .small-boxes-container {
        width: 60%;
    }

    .big-box h3 {
      font-size: 15px;
    }

    .points p {
      font-size: 12.5px;
    }

    .available {
      font-size: 12.5px;
    }

    .available-large {
      font-size: 15px;
    }
}


</style>